@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: rgb(0, 0, 0);
  background-color: rgb(44, 51, 60);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: rgb(226 232 240); /* Set font color to black */
}

html,
body {
  min-height: 100vh; /* Set minimum height to 100% of the viewport height */
  font-family: system-ui;
}
